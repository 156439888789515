@import "index.css";

.appbarContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.loginContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.rightAppbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.loginButton {
  color: white !important;
}

.employerBadge {
  border: 2px solid var(--white-or-black);
  border-radius: 4px;
  margin: 8px 8px 8px 64px;
  height: 48px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.employerLogo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 44px;
  height: 44px;
  border-radius: 2px 0 0 2px;
}

.employerName {
  color: var(--white-or-black);
  font-size: 18px;
  margin-left: 48px;
  padding: 8px;
}

.menuItem {
  width: 64px;
  height: 64px;
  margin: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 4px solid transparent;
  cursor: pointer;
}

.menuItem:hover {
  border-bottom: 4px solid var(--orange);
}

.active {
  border-bottom: 4px solid white;
}

.content {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.drawer {
  height: 80% !important;
  padding: 16px;
}

.mobileLink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--faded);
}

.badgeNew {
  right: -14px !important;
  top: 6px !important;
  white-space: nowrap !important;
}

.mobileLink svg {
  margin-right: 16px;
  color: var(--gray);
  width: 32px;
}

.mobileLink div {
  color: var(--secondary-typography-color);
  font-size: 22px;
}

.mobileLink:active div,
.mobileLink:hover div {
  color: var(--blue-secondary);
}

.mobileLink:active svg,
.mobileLink:hover svg {
  color: var(--blue-secondary);
}

.exitStaffAccount {
  background-color: var(--darkred);
  text-align: center;
  font-size: 12px;
  font-family: var(--mui-font);
}

@import "index.css";

.rolePopover {
  margin: 8px 8px 8px 64px;
  border: 2px solid var(--white-or-black);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
}

.disabledPopover {
  opacity: 0.4;
  filter: blur(2px);
  pointer-events: none;
}

.lockedOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 400;
}

.paper {
  border-radius: 8px !important;
}

.pieChart {
  border-right: 2px solid var(--white-or-black);
  margin-right: 12px;
  padding-right: 12px;
  width: auto;
}

.incomplete {
  width: 32px;
}

.sectionInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.statusButton {
  white-space: nowrap;
  color: white !important;
}

.statusButton svg {
  margin-left: 8px;
}

.submitForApproval {
  background-color: var(--blue) !important;
}

.pendingApproval {
  background-color: var(--orange) !important;
}

.sectionName {
  border-top: 1px solid var(--gray);
  background-color: var(--white-or-black);
  color: var(--gray);
  font-family: var(--main-font);
  padding: 16px;
  width: 252px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
}

.sectionName:hover {
  background-color: rgba(25, 157, 214, 0.1);
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.lastSection {
  border-radius: 0 0 8px 8px !important;
  border-bottom: 1px solid var(--gray) !important;
}

.activeSection {
  border-bottom: 2px solid var(--teal);
  border-top: 2px solid var(--teal);
  color: var(--teal);
}

.lock,
.check,
.open {
  margin-right: 16px;
}

.subSection {
  max-height: 0;
  transition: max-height 800ms;
}

.openSubSection {
  max-height: 4000px;
  transition: max-height 800ms;
}

.subSectionName {
  color: var(--gray);
  font-family: var(--main-font);
  margin: 8px 0;
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
}

.subSectionName:hover {
  color: black;
}

.subSectionName:first-of-type {
  margin-top: 16px;
}

.subSectionName:last-of-type {
  margin-bottom: 16px;
}

.open {
  fill: var(--teal);
}

.lastActiveSection {
  border-radius: 0 !important;
  border-bottom: 2px solid var(--teal) !important;
}

.subSections {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subSection {
  font-family: var(--main-font);
  text-align: left;
}

.activeSubSection {
  color: black;
}

.navigateLabel {
  font-size: 16px;
  font-weight: 500;
}

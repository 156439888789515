.particles {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1302 !important;
}

.particle {
  top: 0;
  position: absolute !important;
  transition: all 5s linear !important;
  z-index: 9 !important;
}

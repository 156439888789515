@import "index.css";

.banner {
  background-color: var(--blue);
  padding: 8px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  right: 0;
  font-family: var(--mui-font);
  left: calc(50% - 175px);
  font-size: 16px;
  color: var(--white-or-black);
  z-index: 4000;
  max-width: 350px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.stayLoggedInButton {
  border-color: white !important;
  color: white !important;
  width: 200px !important;
  padding: 8px !important;
  margin-left: 8px;
}
